import { z } from "zod";

export const GlobalSettingschema = z.object({
  globalBanner: z.object({
    text: z.string(),
    show: z.boolean(),
    userCanHide: z.boolean(),
  }),
  imxOpen: z.boolean(),
});
