import { USER_TYPE } from "types/account-api";
import { z } from "zod";

export const EmailSchema = z.string().email("Invalid e-mail address");

export const BrightStarEmailSchema = EmailSchema.refine(
  (value) => {
    const regex = /@brightstar\.studio$/;
    return regex.test(String(value).toLowerCase());
  },
  { message: "Email must be a Bright Star email" },
);

export const PasswordSchema = z
  .string()
  .min(6, { message: "Password must be at least 8 characters" })
  .max(32, { message: "Password must be at most 32 characters" });

export const UserNameSchema = z
  .string()
  .min(4, { message: "Username must be at least 4 characters" })
  .max(36, { message: "Username must be at most 36 characters" })
  .regex(/^[A-Za-z\-0-9._]+$/, {
    message: "Username can only contain letters, numbers, and .-_ characters",
  });

export const CountryLaxSchema = z.string();
// TODO: The country list is not complete. Some users have countries that are not on the list. ie. "Iran" is not on the list. We need to figure out how to handle this.
// export const CountryStrictSchema = z.union([z.literal(''), z.enum(COUNTRY_LIST)])

export const UserSchema = z.object({
  email: EmailSchema,
  username: UserNameSchema.optional(),
  address1: z.string().optional(),
  country: CountryLaxSchema.optional(),
  fullName: z.string().optional(),
  phoneNumber: z.string().optional(),
  postCode: z.string().optional(),
  state: z.string().optional(),
  walletAddress: z.string().optional(),
  vatNumber: z.string().optional(),
});

// NOTE: When we get users from the AUTH api, if a field is empty (null), it will not be present in the response. However we can not send back null values to the AUTH api. So we might need more constraints on the UpdateUserParamsSchema
export const UpdateUserParamsSchema = z.object({
  username: UserNameSchema,
  address1: z.string(),
  country: CountryLaxSchema,
  fullName: z.string(),
  phoneNumber: z.string(),
  postCode: z.string(),
  state: z.string(),
  vatNumber: z.string(),
});

export const UpdateUserResponseFailureSchema = z.object({
  message: z.string(),
});
export const UpdateUserResponseSuccessSchema = z.object({});

export const EmberKeySchema = z
  .string()
  .min(3, { message: "Promo code must be at least 3 characters" }); // This should maybe be narrowed to some a .UUID() type?

export const EnableGameplayParamsSchema = z.object({
  ember_key: EmberKeySchema,
});

export const EnableGameplayResponseSuccessSchema = z.object({});
export const EnableGameplayResponseFailureSchema = z.object({
  message: z.string(),
});
export type EnableGameplayParams = z.infer<typeof EnableGameplayParamsSchema>;

export const LoginUserParamsSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
  recaptcha: z.string().optional(),
});
export const LoginResponseSuccessSchema = z.object({
  payload: z.object({
    accessToken: z.string(),
  }),
});
export const LoginResponseFailedSchema = z.object({
  message: z.string(),
});

export const CreateAccountResponseFailureSchema = z.object({
  message: z.string(),
});

export const CreateAccountResponseSuccessSchema = z.object({});

export const ForgotPasswordResponseSchema = z.object({
  message: z.string(),
});

export const ActivateAccountResponseFailureSchema = z.object({
  message: z.string(),
});
export const ActivateAccountResponseSuccessSchema = z.object({});

export const ResetPasswordResponseFailureSchema = z.object({
  message: z.string(),
});
export const ResetPasswordResponseSuccessSchema = z.object({});

export const GetWalletResponseSuccessSchema = z.object({
  payload: z.object({
    message: z.string(),
  }),
});

export const GetWalletResponseFailureSchema = z.object({
  message: z.string(),
});

export const SendSignatureParamsSchema = z.object({
  walletAddress: z.string(),
  signature: z.string(),
});
export const SendSignatureResponseSuccessSchema = z.object({});
export const SendSignatureResponseFailureSchema = z.object({
  message: z.string(),
});
